export const weatherReportHeaders = [
  {
    text: "Date",
    value: "date",
    sortable: false,
  },
  {
    text: "Night",
    value: "night",
    sortable: false,
  },
  {
    text: "Morning",
    value: "morning",
    sortable: false,
  },
  {
    text: "Afternoon",
    value: "afternoon",
    sortable: false,
  },
  {
    text: "Evening",
    value: "evening",
    sortable: false,
  },
  {
    text: "Low (°C)",
    value: "minTemp",
  },
  {
    text: "High (°C)",
    value: "maxTemp",
  },
  {
    text: "Min Precip",
    value: "minPrecip",
  },
  {
    text: "Max Precip",
    value: "maxPrecip",
  },
  {
    text: "Min Wind",
    value: "minWindSpeed",
  },
  {
    text: "Max Wind",
    value: "maxWindSpeed",
  },
  {
    text: "Min Humidity",
    value: "minHumidity",
  },
  {
    text: "Max Humidity",
    value: "maxHumidity",
  },
  {
    text: "Min Pressure",
    value: "minPressure",
  },
  {
    text: "Max Pressure",
    value: "maxPressure",
  },
  {
    text: "Min Clouds",
    value: "minClouds",
  },
  {
    text: "Max Clouds",
    value: "maxClouds",
  },
]
